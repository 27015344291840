import React from "react"
import PropTypes from "prop-types"
import TextLockup from "components/elements/textLockup"
import Layout from "components/blocks/layout"
import Table from "./table"
import "./comparisonTable.scss"

/* Comparison Table */

const ComparisonTable = ({ intro, table }) => (
  <Layout className="comparisonTable-section" textLockup={intro}>
    <div className="comparison-chart">
      <Table {...table} />
    </div>
  </Layout>
)

ComparisonTable.props = {
  intro: PropTypes.shape(TextLockup.props),
  table: PropTypes.shape(Table.props),
}

ComparisonTable.propTypes = ComparisonTable.props

export default ComparisonTable
