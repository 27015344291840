import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import Layout from "components/blocks/layout"
import TextLockup from "components/elements/textLockup"
import IntroCard from "./introCard"
import Timeline from "./timeline"
import ToggleButtons from "./toggleButtons"
import "./patientJourney.scss"

const PatientJourney = ({ disclaimer, intro, patients }) => {
  const container = useRef(null)
  const names = patients.map(patient => patient.name)
  const [activeId, setActiveId] = useState("All")

  const toggleActiveId = id => {
    setActiveId(id)
    container.current.querySelector(".main-component").scrollLeft = 0
  }

  useEffect(() => {
    container.current.setAttribute("data-active-id", activeId)
  }, [activeId])

  return (
    <Layout
      actionContent={
        <ToggleButtons
          currentId={activeId}
          names={names}
          toggleActive={toggleActiveId}
        />
      }
      className="patient-journey"
      footnoteText={disclaimer}
      ref={container}
      textLockup={intro}
    >
      <div className="timeline__container">
        <div
          className="patient-journey__container patient-journey__container--all"
          data-isactive={activeId === "All" && true}
        >
          <div className="patient-journey__all">
            {patients.map((patient, i) => (
              <IntroCard
                {...patient}
                key={i}
                toggleActive={() => toggleActiveId(patient.name)}
              />
            ))}
          </div>
        </div>
        {patients.map((patient, i) => {
          const nextId = i + 1
          const nextName = patients[nextId] ? patients[nextId].name : "All"
          const nextButtonText = nextName === "All" ? "Start Again" : nextName
          return (
            <div
              className="patient-journey__container"
              data-isactive={activeId === patient.name && true}
              key={i}
            >
              <Timeline
                {...patient}
                data-isactive={activeId === patient.name && true}
                nextChild={nextButtonText}
                toggleActive={() => toggleActiveId(nextName)}
              />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

PatientJourney.props = {
  disclaimer: PropTypes.string,
  intro: PropTypes.shape(TextLockup.props).isRequired,
  patients: PropTypes.arrayOf(
    PropTypes.shape({
      ...IntroCard.props,
      ...Timeline.props,
    })
  ).isRequired,
}

PatientJourney.propTypes = PatientJourney.props

export default PatientJourney
