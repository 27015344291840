import React from "react"
import PropTypes from "prop-types"
import { iconTheme } from "utils/iconTheme"
import { iconProps, themeProps } from "../../props"
import Icon from "components/elements/Icon"
import Heading4 from "components/type/Heading4"
import Heading6 from "components/type/Heading6"
import Card from "../../card"
import "../../card/card.scss"
import "./storyCard.scss"

const StoryCard = ({ icon, text, theme, timeFrame }) => {
  const { fill, stroke } = iconTheme(theme)

  return (
    <li className="timeline-entry">
      <Heading4>{timeFrame}</Heading4>
      <Card className="story-card" theme={theme}>
        {icon && (
          <Icon
            className="story-card__icon"
            fill={fill}
            icon={icon}
            stroke={stroke}
          />
        )}
        <Heading6>{text.text}</Heading6>
      </Card>
    </li>
  )
}

StoryCard.props = {
  icon: PropTypes.oneOf(iconProps),
  text: PropTypes.shape({
    text: PropTypes.string,
  }),
  timeFrame: PropTypes.string.isRequired,
}

StoryCard.propTypes = {
  ...StoryCard.props,
  theme: PropTypes.oneOf(themeProps).isRequired,
}

export default StoryCard
