import React, { useImperativeHandle, useRef, forwardRef } from "react"
import PropTypes from "prop-types"
import Media from "components/blocks/media"
import "./card.scss"

const Card = forwardRef(
  ({ ariaHidden, image, location, name, quote, roles, z }, ref) => {
    const containerRef = useRef()

    useImperativeHandle(ref, () => ({
      get cardContainer() {
        return containerRef?.current
      },
    }))

    return (
      <div
        aria-hidden={ariaHidden}
        className="clinician-card"
        ref={containerRef}
        style={{ zIndex: z }}
      >
        <Media className="image" image={image} ratio={{ width: 1 }} />
        <div className="wrapper">
          <p className="copy">&quot;{quote.quote}&quot;</p>
          <div className="details-wrapper">
            <div>
              <div className="location-tag">
                <img
                  alt="location pin icon"
                  src="/images/icon-location-pin.svg"
                />
                {location}
              </div>
            </div>
            <div className="name-wrapper">
              <b className="name">{name}</b>
              <p className="titles">{roles.join(", ")}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

Card.props = {
  ...Media.props,
  ariaHidden: PropTypes.bool,
  location: PropTypes.string,
  name: PropTypes.string,
  quote: PropTypes.shape({
    quote: PropTypes.string,
  }),
  roles: PropTypes.arrayOf(PropTypes.string),
  z: PropTypes.number,
}

Card.displayName = "ClinicianCard"

Card.propTypes = Card.props

export default Card
