import React from "react"
import PropTypes from "prop-types"
import Layout from "components/blocks/layout"
import MultiColumn from "components/blocks/multiColumn"
import TextLockup from "components/elements/textLockup"
import Button from "components/elements/styledButton"
import Card from "components/blocks/card"
import "./productOffering.scss"

/* ProductOffering */
/**
 * This is where the description for this ProductOffering component goes.
 */
const ProductOffering = ({ action, intro, offerings }) => (
  <div className="g-product-offering__wrapper">
    <Layout
      actionContent={<Button {...action} />}
      className="g-product-offering__layout max-container"
      textLockup={{
        ...intro,
        text: { ...intro?.text, type: "p1" },
      }}
    >
      <MultiColumn className="g-product-offering__secondary-content">
        {offerings.map((offering, i) => (
          <Card
            {...offering}
            key={i}
            text={{ ...offering?.text, type: "p1" }}
          />
        ))}
      </MultiColumn>
    </Layout>
  </div>
)

ProductOffering.props = {
  action: PropTypes.shape(Button.props),
  intro: PropTypes.shape(TextLockup.props),
  offerings: PropTypes.arrayOf(PropTypes.shape(Card.props)),
}

ProductOffering.propTypes = ProductOffering.props

export default ProductOffering
