import React from "react"
import PropTypes from "prop-types"
import "./listItems.scss"

/**
 * Component for each list items
 */

const ListItems = ({ list }) => (
  <ul
    className="listItems"
    style={{
      gridTemplateRows: `repeat(${Math.ceil(list.length / 2)}, auto)`,
    }}
  >
    {list.map((item, i) => (
      <li className="listItems__item" key={i}>
        {item}
      </li>
    ))}
  </ul>
)

ListItems.props = {
  list: PropTypes.arrayOf(PropTypes.string),
}

ListItems.propTypes = ListItems.props

export default ListItems
