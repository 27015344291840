import React from "react"
import PropTypes from "prop-types"
import Button from "components/elements/styledButton"
import Heading4 from "components/type/Heading4"

const NextCard = ({ nextChild, toggleActive }) => (
  <li className="timeline-entry">
    <Heading4>See the next journey</Heading4>
    <Button color={["yellow"]} onClick={toggleActive} text={nextChild} />
  </li>
)

NextCard.props = {
  nextChild: PropTypes.string.isRequired,
}

NextCard.propTypes = {
  ...NextCard.props,
  toggleActive: PropTypes.func.isRequired,
}

export default NextCard
