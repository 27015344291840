import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "components/elements/styledButton"
import "./toggleButtons.scss"

const ToggleButtons = ({ currentId, names, toggleActive }) => {
  const [activeId] = useState(currentId)

  return (
    <div className="patient-journey__buttons">
      <div className="toggle-buttons">
        <Button
          isDisabled={currentId === "All" && true}
          onClick={() => toggleActive("All")}
          text="All"
        />
        {names.map((name, i) => (
          <Button
            isDisabled={(activeId === name || currentId === name) && true}
            key={i}
            onClick={() => toggleActive(name)}
            text={name}
          />
        ))}
      </div>
    </div>
  )
}

ToggleButtons.props = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
}

ToggleButtons.propTypes = {
  ...ToggleButtons.propTypes,
  currentId: PropTypes.string.isRequired,
  toggleActive: PropTypes.func.isRequired,
}

export default ToggleButtons
