import React from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import { renderClasses } from "utils/renderClasses"
import Media from "components/blocks/media"
import MultiColumn from "components/blocks/multiColumn"
import TextLockup from "components/elements/textLockup"
import "./card.scss"

const Card = ({ className, cmsEntryTitle, content, image }) => (
  <MultiColumn
    className={renderClasses("chaptered-scroll__card", [
      [className, className],
    ])}
    data-title={cmsEntryTitle}
    id={
      cmsEntryTitle ? slugify(cmsEntryTitle, { lower: true, strict: true }) : ""
    }
    valign="middle"
  >
    <Media
      {...image}
      className="chaptered-scroll__card--image"
      hAlign="left"
      height="auto"
      radius="12px"
      ratio={{ width: 1 }}
    />
    <div className="chaptered-scroll__card--copy">
      <TextLockup
        {...content}
        action={
          content.action
            ? {
                ...content.action,
                color: content.action.color || ["ivory"],
                isOutlined: content.action.isOutlined || true,
                isSmall: content.action.isSmall || true,
              }
            : null
        }
      />
    </div>
  </MultiColumn>
)

Card.props = {
  className: PropTypes.string,
  cmsEntryTitle: PropTypes.string,
  content: PropTypes.shape(TextLockup.props),
  image: PropTypes.shape(Media.props),
}

Card.propTypes = Card.props

export default Card
