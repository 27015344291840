import React from "react"
import PropTypes from "prop-types"
import Layout from "components/blocks/layout"
import ListItems from "components/blocks/listItems"
import TextLockup from "components/elements/textLockup"
import "./whatWeTreat.scss"

/* WhatWeTreat */
/**
 * Section containing text lockup and list component
 */
const WhatWeTreat = ({ intro, treatments }) => (
  <Layout className="g-what-we-treat" layout="left-top" textLockup={intro}>
    <div className="g-what-we-treat__container">
      <ListItems list={treatments} />
    </div>
  </Layout>
)

WhatWeTreat.props = {
  intro: PropTypes.shape(TextLockup.props),
  treatments: PropTypes.arrayOf(PropTypes.string),
}

WhatWeTreat.propTypes = WhatWeTreat.props

export default WhatWeTreat
