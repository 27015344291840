import React from "react"
import PropTypes from "prop-types"
import Layout from "components/blocks/layout"
import "./basicHero.scss"

/* BasicHero */

const BasicHero = ({ headline }) => (
  <Layout className="g-basic-hero" textLockup={{ title: { text: headline } }} />
)

BasicHero.props = {
  headline: PropTypes.shape({
    json: PropTypes.object,
  }),
}

BasicHero.propTypes = BasicHero.props

export default BasicHero
