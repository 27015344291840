import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import { themeProps } from "../props"
import "./card.scss"

const Card = ({ children, className, theme }) => (
  <div
    className={renderClasses(
      `patient-journey-card patient-journey-card__${theme}`,
      [[className, className]]
    )}
  >
    {children}
  </div>
)

Card.props = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Card.propTypes = {
  ...Card.props,
  theme: PropTypes.oneOf(themeProps).isRequired,
}

export default Card
