import React from "react"
import PropTypes from "prop-types"
import Layout from "components/blocks/layout"
import TextLockup from "components/elements/textLockup"
import MultiList from "components/blocks/multiList"
import "./coordinatedCareTeam.scss"

/* CoordinatedCareTeam */
/**
 * This is where the description for this CoordinatedCareTeam component goes.
 */
const CoordinatedCareTeam = ({ intro, teams }) => (
  <Layout
    className="g-coordinated-care-team"
    layout="left-top"
    textLockup={intro}
  >
    <div className="g-coordinated-care-team__container">
      <MultiList list={teams} />
    </div>
  </Layout>
)

CoordinatedCareTeam.props = {
  intro: PropTypes.shape(TextLockup.props),
  list: PropTypes.arrayOf(PropTypes.shape(MultiList.props)),
}

CoordinatedCareTeam.propTypes = CoordinatedCareTeam.props

export default CoordinatedCareTeam
