export const themeProps = ["DarkBlue", "Pink", "Green", "Blue", "Yellow"]

export const iconProps = [
  "Appointment",
  "BehavioralTherapy",
  "ComprehensiveCare",
  "DevelopmentalTherapy",
  "Family",
  "Integrated",
  "Medical",
  "MedicalSecondary",
  "Neurodevelopment",
  "Nutrition",
  "Personalized",
  "Progress",
  "Research",
  "ResearchSecondary",
  "SensoryMotor",
  "SpeechTherapy",
  "Telehealth",
  "WholeBody",
]
