import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import "./numberCarouselControl.scss"

const NumberCarouselControl = ({
  clickHandler,
  currentItem,
  numberOfItems,
}) => (
  <div className="page-control">
    {[...Array(numberOfItems)].map((_, i) => (
      <button
        className={renderClasses("page-control__step", [
          [currentItem === i, "page-control__step--selected"],
        ])}
        key={i}
        onClick={() => clickHandler(i)}
      >
        {i + 1}
      </button>
    ))}
  </div>
)

NumberCarouselControl.props = {
  clickHandler: PropTypes.func,
  currentItem: PropTypes.number,
  numberOfItems: PropTypes.number,
}

NumberCarouselControl.propTypes = NumberCarouselControl.props

export default NumberCarouselControl
