import React from "react"
import PropTypes from "prop-types"
import Button from "components/elements/styledButton"
import TextLockup from "components/elements/textLockup"
import Layout from "components/blocks/layout"
import "./preFooter.scss"

const PreFooter = ({ actions, intro }) => {
  const buttons = (
    <div>
      {actions.map((button, i) => (
        <Button {...button} color={button.color || ["blue"]} key={i} />
      ))}
    </div>
  )

  return (
    <Layout actionContent={buttons} className="pre-footer" textLockup={intro} />
  )
}

PreFooter.props = {
  actions: PropTypes.arrayOf(PropTypes.shape(Button.props)),
  intro: PropTypes.shape(TextLockup.props),
}

PreFooter.propTypes = PreFooter.props

export default PreFooter
