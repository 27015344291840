import React from "react"
import PropTypes from "prop-types"
import COMPONENTS from "utils/componentConstants"
import BasicHero from "components/sections/basicHero"
import CareServices from "components/sections/careServices"
import ClinicianCarousel from "components/sections/clinicianCarousel"
import ComparisonTable from "components/sections/comparisonTable"
import CoordinatedCareTeam from "components/sections/coordinatedCareTeam"
import Faq from "components/sections/faq"
import Hero from "components/sections/hero"
import InsuranceBanner from "components/sections/insuranceBanner"
import Layout from "components/blocks/layout"
import PaginatedScroll from "components/sections/paginatedScroll"
import PatientJourney from "components/sections/patientJourney"
import PreFooter from "components/sections/preFooter"
import ProductOffering from "components/sections/productOffering"
import ServicesHero from "components/sections/servicesHero"
import SplitSection from "components/sections/splitSection"
import Statistics from "components/sections/statistics"
import Testimonials from "components/sections/testimonials"
import WhatWeDo from "components/sections/whatWeDo"
import WhatWeTreat from "components/sections/whatWeTreat"

const GlobalTemplate = ({ sections }) =>
  sections.map((section, i) => {
    switch (section.__typename) {
      case COMPONENTS.CareServices:
        return <CareServices {...section} key={i} />

      case COMPONENTS.Clinicians:
        return <ClinicianCarousel {...section} key={i} />

      case COMPONENTS.ComparisonTable:
        return <ComparisonTable {...section} key={i} />

      case COMPONENTS.CoordinatedCareTeam:
        return <CoordinatedCareTeam {...section} key={i} />

      case COMPONENTS.Faq:
        return <Faq {...section} key={i} />

      case COMPONENTS.Hero:
        return <Hero {...section} key={i} />

      case COMPONENTS.HeroHeadline:
        return <BasicHero {...section} key={i} />

      case COMPONENTS.InsuranceBanner:
        return <InsuranceBanner displayMode="names" {...section} key={i} />

      case COMPONENTS.Layout:
        return <Layout {...section} key={i} />

      case COMPONENTS.PaginatedScroll:
        return <PaginatedScroll {...section} key={i} />

      case COMPONENTS.PatientJourney:
        return <PatientJourney {...section} key={i} />

      case COMPONENTS.PreFooter:
        return <PreFooter {...section} key={i} />

      case COMPONENTS.ProductOffering:
        return <ProductOffering {...section} key={i} />

      case COMPONENTS.ServicesHero:
        return <ServicesHero {...section} key={i} />

      case COMPONENTS.SplitContent:
        return <SplitSection {...section} key={i} />

      case COMPONENTS.Statistics:
        return <Statistics {...section} key={i} />

      case COMPONENTS.Testimonials:
        return <Testimonials {...section} key={i} />

      case COMPONENTS.WhatWeDo:
        return <WhatWeDo {...section} key={i} />

      case COMPONENTS.WhatWeTreat:
        return <WhatWeTreat {...section} key={i} />

      default:
        return null
    }
  })

GlobalTemplate.props = {
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(CareServices.props),
      PropTypes.shape(ClinicianCarousel.props),
      PropTypes.shape(ComparisonTable.props),
      PropTypes.shape(CoordinatedCareTeam.props),
      PropTypes.shape(Faq.props),
      PropTypes.shape(Hero.props),
      PropTypes.shape(BasicHero.props),
      PropTypes.shape(InsuranceBanner.props),
      PropTypes.shape(Layout.props),
      PropTypes.shape(PaginatedScroll.props),
      PropTypes.shape(PatientJourney.props),
      PropTypes.shape(PreFooter.props),
      PropTypes.shape(ProductOffering.props),
      PropTypes.shape(ServicesHero.props),
      PropTypes.shape(SplitSection.props),
      PropTypes.shape(Statistics.props),
      PropTypes.shape(Testimonials.props),
      PropTypes.shape(WhatWeDo.props),
      PropTypes.shape(WhatWeTreat.props),
    ])
  ),
  slug: PropTypes.string,
  title: PropTypes.string,
}

GlobalTemplate.propTypes = GlobalTemplate.props

export default GlobalTemplate
