import React, { useState, useEffect, createRef, useCallback } from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import { Power2, Power0 } from "gsap/all"
import { mediumAnim } from "utils/gsapAnims"
import useWindowDimensions from "utils/useWindowDimensions"
import HELPERS from "utils/helpers"
import TextLockup from "components/elements/textLockup"
import Layout from "components/blocks/layout"
import { Arrow } from "components/icons"
import Card from "./card"
import "./clinicianCarousel.scss"

const ClinicianCarousel = ({ clinicians, intro }) => {
  const [cardRefArray, setCardRefArray] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [animating, setIsAnimating] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const { width } = useWindowDimensions()
  const [renderableData, setRenderableData] = useState([])
  const shuffleDuration = mediumAnim
  const layoutStackWidthThreshold = 1000
  const numberOfCards = clinicians.length

  const currentCard = useCallback(() => {
    return cardRefArray[currentIndex]?.current
  }, [cardRefArray, currentIndex])

  const backgroundRenderables = useCallback(() => {
    return HELPERS.smartSlice(
      cardRefArray,
      (currentIndex + 1) % numberOfCards,
      numberOfCards - 1
    )
      .slice(0, Math.min(numberOfCards, numberOfCards) - 1)
      .map(c => c?.current)
  }, [cardRefArray, currentIndex, numberOfCards])

  const layoutCards = useCallback(() => {
    // this allows us to re-layout on a breakpoint change
    // while keeping the right order of z indexes
    const cardContainers = [currentCard()?.cardContainer].concat(
      backgroundRenderables().map(c => c?.cardContainer)
    )

    for (const [i, ref] of cardContainers.entries()) {
      if (ref) {
        gsap.set(ref, {
          x: i * cardOffset(),
          y: i * cardOffset(),
        })
      }
    }
  }, [backgroundRenderables, currentCard])

  const cardOffset = () => {
    return typeof window !== "undefined" &&
      window.innerWidth <= layoutStackWidthThreshold
      ? 12
      : 24
  }

  const animateToNextCard = e => {
    if (
      animating ||
      (e.type === "keydown" && e.keyCode !== 13 && e.keyCode !== 32)
    )
      return

    if (e.keyCode === 32) e.preventDefault()

    toggleForwardAnimation()
  }

  const animateToPreviousCard = e => {
    if (
      animating ||
      (e.type === "keydown" && e.keyCode !== 13 && e.keyCode !== 32)
    )
      return

    if (e.keyCode === 32) e.preventDefault()

    toggleBackwardAnimation()
  }

  const toggleForwardAnimation = () => {
    setIsAnimating(true)

    const topCard = currentCard()?.cardContainer
    const otherCards = backgroundRenderables().map(c => c?.cardContainer)

    // Animate the top most card top left and fade out
    gsap.to(topCard, {
      duration: shuffleDuration / 2,
      ease: Power2.easeIn,
      onComplete: slideOutForwardComplete,
      opacity: 0,
      x: -cardOffset() / 2,
      y: -cardOffset() / 2,
    })

    // Animate the other cards to their new position
    for (const [i, container] of otherCards.entries()) {
      gsap.to(container, {
        duration: shuffleDuration,
        ease: Power2.easeInOut,
        x: i * cardOffset(),
        y: i * cardOffset(),
      })
    }
  }

  const slideOutForwardComplete = () => {
    currentCard().cardContainer.style.zIndex = 0

    // Move the top card to back, fade back in
    gsap.set(currentCard()?.cardContainer, {
      x: cardOffset() * (numberOfCards - 1),
      y: cardOffset() * (numberOfCards - 1),
    })

    gsap.to(currentCard()?.cardContainer, {
      duration: shuffleDuration / 2,
      ease: Power2.easeOut,
      onComplete: updateCards,
      opacity: 1,
    })
  }

  const toggleBackwardAnimation = () => {
    setIsAnimating(true)

    // probably an easier way to get bottom card and an array of
    // the other cards but i'm just zoomin' along
    const topCard = currentCard()?.cardContainer
    const otherCards = backgroundRenderables().map(c => c?.cardContainer)
    const bottomCard = otherCards.pop()
    const allOtherCards = [topCard].concat(otherCards)

    // fade out bottom card
    gsap.to(bottomCard, {
      duration: shuffleDuration / 2,
      ease: Power2.easeIn,
      onComplete: fadeOutBackwardComplete,
      opacity: 0,
    })

    // Animate the other cards to their new position
    for (const [i, container] of allOtherCards.entries()) {
      gsap.to(container, {
        duration: shuffleDuration,
        ease: Power2.easeInOut,
        x: (i + 1) * cardOffset(),
        y: (i + 1) * cardOffset(),
      })
    }
  }

  const fadeOutBackwardComplete = () => {
    const otherCards = backgroundRenderables().map(c => c?.cardContainer)
    const bottomCard = otherCards.pop()
    bottomCard.style.zIndex = numberOfCards

    // Move the bottom card to front, fade back in
    gsap.set(bottomCard, {
      x: -cardOffset() / 4,
      y: -cardOffset() / 4,
    })

    gsap.to(bottomCard, {
      duration: shuffleDuration / 2.5,
      ease: Power0.easeNone,
      onComplete: updateCardsReverse,
      opacity: 1,
      x: 0,
      y: 0,
    })
  }

  const updateCards = () => {
    const newIndex = (currentIndex + 1) % numberOfCards

    setCurrentIndex(newIndex)
    setIsAnimating(false)
  }

  const updateCardsReverse = () => {
    let newIndex = currentIndex - 1

    if (newIndex < 0) {
      newIndex = numberOfCards - 1
    }

    setCurrentIndex(newIndex)
    setIsAnimating(false)
  }

  useEffect(() => {
    setCardRefArray(elRefs =>
      Array(numberOfCards)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    )
  }, [numberOfCards])

  useEffect(() => {
    if (cardRefArray.length < numberOfCards - 1) return
    layoutCards()
  }, [cardRefArray, layoutCards, numberOfCards])

  useEffect(() => {
    if (isMobile && width > layoutStackWidthThreshold) {
      setIsMobile(false)
    } else if (!isMobile && width <= layoutStackWidthThreshold) {
      setIsMobile(true)
    }
  }, [width, isMobile])

  useEffect(() => {
    layoutCards()
  }, [isMobile, layoutCards])

  useEffect(() => {
    setRenderableData(
      HELPERS.smartSlice(clinicians, currentIndex, numberOfCards)
    )
  }, [clinicians, currentIndex, numberOfCards])

  const sliderNav = (
    <div className="clinicians-nav__container">
      <div
        aria-label="go to previous card"
        className="clinicians-nav clinicians-nav__prev"
        onClick={animateToPreviousCard}
        onKeyDown={animateToPreviousCard}
        role="button"
        tabIndex="0"
      >
        <Arrow direction="left" fill="#FFEBCA" fillSecondary="#122F4E" />
      </div>
      <div
        aria-label="go to next card"
        className="clinicians-nav clinicians-nav__next"
        onClick={animateToNextCard}
        onKeyDown={animateToNextCard}
        role="button"
        tabIndex="0"
      >
        <Arrow fill="#FFEBCA" fillSecondary="#122F4E" />
      </div>
    </div>
  )

  return (
    <Layout actionContent={sliderNav} className="clinitians" textLockup={intro}>
      <div
        className="card-container"
        style={
          /* Add specific padding based on number of cards to assist layout */
          {
            paddingBottom: Math.max(numberOfCards - 2, 0) * cardOffset(),
            paddingRight: (numberOfCards * cardOffset()) / 2,
          }
        }
      >
        {renderableData.map((clinician, localIndex) => {
          const realIndex = (currentIndex + localIndex) % numberOfCards
          const isCardVisible = realIndex === 0
          return (
            <Card
              {...clinician}
              ariaHidden={!isCardVisible}
              key={realIndex}
              ref={cardRefArray[realIndex]}
              z={numberOfCards - localIndex}
            />
          )
        })}
      </div>
    </Layout>
  )
}

ClinicianCarousel.props = {
  clinicians: PropTypes.arrayOf(PropTypes.shape(Card.props)),
  intro: PropTypes.shape(TextLockup.props),
}

ClinicianCarousel.propTypes = ClinicianCarousel.props

export default ClinicianCarousel
