import React, { useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { COLORS } from "utils/constants"
import { Arrow } from "components/icons"
import DropdownIndicator from "./indicator"
import "./dropdownNav.scss"

/**
 *
 */
const DropdownNav = ({ label = "I want to learn about", options }) => {
  const [destination, setDestination] = useState(options[0].value)
  const [destinationLabel, setDestinationLabel] = useState(options[0].label)
  const [iconFill, setIconFill] = useState("#ffebca")

  return (
    <div className="dropdown-nav__wrapper">
      <label className="dropdown-nav-label" id="dropdown-nav-label">
        {label}
      </label>
      <Select
        aria-label={`Dropdown with ${options.length} options`}
        className="dropdown-nav"
        classNamePrefix="dropdown-nav"
        components={{ DropdownIndicator }}
        defaultValue={options[0]}
        isClearable={false}
        isSearchable={false}
        menuPlacement="bottom"
        onChange={e => {
          setDestination(e.value)
          setDestinationLabel(e.label)
        }}
        options={options}
        styles={{
          control: () => {},
          dropdownIndicator: () => {},
          indicatorSeparator: () => {},
          menu: () => {},
          menuList: () => {},
          option: () => {},
          singleValue: () => {},
          valueContainer: () => {},
        }}
      />
      <a
        aria-label={`Visit ${destinationLabel} page`}
        className="dropdown-nav-button"
        href={destination}
        onMouseEnter={() => setIconFill(COLORS.yellow)}
        onMouseLeave={() => setIconFill(COLORS.pastelYellow)}
        role="button"
      >
        <Arrow
          className="--m-to-l"
          fill={iconFill}
          fillSecondary={COLORS.darkBlue}
        />
      </a>
    </div>
  )
}

DropdownNav.props = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ),
}

DropdownNav.propTypes = DropdownNav.props

export default DropdownNav
