import React from "react"
import PropTypes from "prop-types"
import { components } from "react-select"
import { Caret } from "components/icons"

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Caret
      direction={props.selectProps.menuIsOpen ? "up" : "down"}
      size="large"
      stroke="currentColor"
      strokeWidth="3"
    />
  </components.DropdownIndicator>
)

DropdownIndicator.props = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }),
}

DropdownIndicator.propTypes = DropdownIndicator.props

export default DropdownIndicator
