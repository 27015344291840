import { useState, useEffect } from "react"

function getWindowDimensions() {
  const { innerHeight: height, innerWidth: width } = window
  return {
    height,
    width,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    height: 0,
    width: -1,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    const handleOrientation = () => {
      // After orientationchange, add a one-time resize event
      const afterOrientationChange = () => {
        handleResize()
        // Remove the resize event listener after it has executed
        window.removeEventListener("resize", afterOrientationChange)
      }
      window.addEventListener("resize", afterOrientationChange)
    }

    window.addEventListener("orientationchange", handleOrientation)
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("orientationchange", handleResize)
    }
  }, [])

  return windowDimensions
}
