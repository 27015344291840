import React from "react"
import PropTypes from "prop-types"
import { themeProps } from "../../props"
import Heading4 from "components/type/Heading4"
import Paragraph from "components/type/Paragraph"
import Card from "../../card"
import "../../card/card.scss"
import "./servicesCard.scss"

const ServicesCard = ({ ageJoined, diagnosis, name, theme, treatments }) => (
  <li className="timeline-entry">
    <Heading4>{name}</Heading4>
    <Card className="services-card" theme={theme}>
      <div className="services-card__description">
        <Paragraph semiBold type="p2">
          Joined Cortica at age {ageJoined}
        </Paragraph>
        <Paragraph type="p2">Diagnosis: {diagnosis}</Paragraph>
      </div>
      <div className="services-received-container">
        <Paragraph semiBold type="p2">
          {" "}
          Services Received{" "}
        </Paragraph>
        <ul className="services-card__treatments">
          {treatments.map((item, i) => (
            <li key={i}>
              <Paragraph type="p2">{item}</Paragraph>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  </li>
)

ServicesCard.props = {
  ageJoined: PropTypes.string.isRequired,
  diagnosis: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  treatments: PropTypes.arrayOf(PropTypes.string).isRequired,
}

ServicesCard.propTypes = {
  ...ServicesCard.props,
  theme: PropTypes.oneOf(themeProps).isRequired,
}

export default ServicesCard
