import React from "react"
import PropTypes from "prop-types"
import Progress from "./progress"
import "./statistic.scss"

/* Statistic */
/**
 * Statistic displayed with a bar chart for statistics section
 */
const Statistic = ({ copy, percent }) => (
  <div className="barChart-statistic">
    <Progress value={percent} />
    <div className="barChart-statistic__copy">{copy}</div>
  </div>
)

Statistic.props = {
  copy: PropTypes.string,
  percent: PropTypes.number,
}

Statistic.propTypes = Statistic.props

export default Statistic
