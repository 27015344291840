import React from "react"
import PropTypes from "prop-types"
import Layout from "components/blocks/layout"
import MultiColumn from "components/blocks/multiColumn"
import Card from "components/blocks/card"
import Button from "components/elements/styledButton"
import TextLockup from "components/elements/textLockup"
import "./whatwedo.scss"

const WhatWeDo = ({ action, content, intro }) => {
  const cardContent = content.map(card => ({
    ...card,
    media: {
      ...card.media,
      radius: "8px",
      ratio: {
        width: 1,
      },
    },
  }))

  return (
    <Layout
      actionContent={<Button {...action} />}
      className="g-what-we-do"
      textLockup={intro}
    >
      <MultiColumn>
        {cardContent.map((c, i) => (
          <Card {...c} key={i} />
        ))}
      </MultiColumn>
    </Layout>
  )
}

WhatWeDo.props = {
  action: PropTypes.shape(Button.props),
  content: PropTypes.arrayOf(PropTypes.shape(Card.props)),
  intro: PropTypes.shape(TextLockup.props),
}

WhatWeDo.propTypes = WhatWeDo.props

export default WhatWeDo
