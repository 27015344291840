import React from "react"
import PropTypes from "prop-types"
import Layout from "components/blocks/layout"
import TextLockup from "components/elements/textLockup"
import Statistic from "./statistic"
import "./statistics.scss"

/**
 * Statistics Section
 */
const Statistics = ({ barCharts, footnote, intro }) => (
  <Layout
    className="statistics-section"
    footnoteText={footnote}
    layout="50-50"
    textLockup={intro}
  >
    <div className="statistics-section__barChart">
      {barCharts.map((chart, i) => (
        <Statistic {...chart} key={i} />
      ))}
    </div>
  </Layout>
)

Statistics.props = {
  barCharts: PropTypes.arrayOf(PropTypes.shape(Statistic.props)),
  footnote: PropTypes.string,
  intro: PropTypes.shape(TextLockup.props),
}

Statistics.propTypes = Statistics.props

export default Statistics
