import React from "react"
import PropTypes from "prop-types"
import NextCard from "./nextCard"
import ServicesCard from "./servicesCard"
import StoryCard from "./storyCard"
import { themeProps } from "../props"
import "./timeline.scss"

const Timeline = ({
  ageJoined,
  diagnosis,
  name,
  nextChild = "Start Again",
  theme = "green",
  timeline,
  toggleActive,
  treatments,
}) => (
  <ul className={`timeline timeline__${theme}`}>
    <ServicesCard
      ageJoined={ageJoined}
      diagnosis={diagnosis}
      name={name}
      theme={theme}
      treatments={treatments}
    />
    {timeline.map((milestone, i) => (
      <StoryCard {...milestone} key={i} theme={theme} />
    ))}
    <NextCard nextChild={nextChild} toggleActive={toggleActive} />
  </ul>
)

Timeline.props = {
  timeline: PropTypes.arrayOf(PropTypes.shape(StoryCard.props)).isRequired,
  treatments: PropTypes.arrayOf(PropTypes.string).isRequired,
}

Timeline.propTypes = {
  ...Timeline.props,
  ageJoined: PropTypes.string.isRequired,
  diagnosis: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nextChild: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(themeProps).isRequired,
  toggleActive: PropTypes.func.isRequired,
}

export default Timeline
