import React from "react"
import PropTypes from "prop-types"
import Button from "components/elements/styledButton"
import Layout from "components/blocks/layout"
import ListItems from "components/blocks/listItems"
import Media from "components/blocks/media"
import MultiColumn from "components/blocks/multiColumn"
import TextLockup from "components/elements/textLockup"
import "./servicesHero.scss"

/* ServicesHero */
/**
 * This is where the description for this ServicesHero component goes.
 */

const ServicesHero = ({ action, image, services, text, title }) => {
  const list = services.map(s => s.text)

  return (
    <div className="servicesHero">
      <div className="servicesHero__container">
        <Layout
          className="servicesHero__content max-container"
          textLockup={{ alignment: "center", title }}
        >
          <div className="servicesHero__media">
            <Media
              height="auto"
              image={image}
              radius="12px"
              ratio={{ height: 9, width: 16 }}
            />
          </div>
          <MultiColumn className="servicesHero__secondary-content">
            <div className="servicesHero__textLockup">
              <TextLockup text={text} />
              <Button {...action} className="servicesHero__CTA" />
            </div>
            <div className="servicesHero__list">
              <ListItems list={list} />
            </div>
          </MultiColumn>
        </Layout>
      </div>
    </div>
  )
}

ServicesHero.props = {
  ...TextLockup.props,
  ...Media.props,
  action: PropTypes.shape(Button.props),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    })
  ),
}

ServicesHero.propTypes = ServicesHero.props

export default ServicesHero
