import React, { forwardRef, useImperativeHandle, useRef } from "react"
import PropTypes from "prop-types"
import Paragraph from "components/type/Paragraph"
import Media from "components/blocks/media"
import "./card.scss"

const Card = forwardRef(
  (
    {
      ariaHidden,
      color = "dark-yellow",
      image,
      quote,
      quoteAttribution,
      statistics,
      z,
    },
    ref
  ) => {
    const containerRef = useRef()

    useImperativeHandle(ref, () => ({
      get cardContainer() {
        return containerRef?.current
      },
    }))

    return (
      <div
        aria-hidden={ariaHidden}
        className={`testimonial-card testimonial-card__${color}`}
        ref={containerRef}
        style={{ zIndex: z }}
      >
        <div className="testimonial-card-wrapper">
          <div className="testimonial-card__image-container">
            <Media {...image} height="100%" radius="4px" width="100%" />
          </div>
          <div className="testimonial-card__details">
            <div className="testimonial-card__details-wrapper">
              <Paragraph className="testimonial-card__details-copy" type="p1">
                {quote.quote}
              </Paragraph>
              <p className="testimonial-card__details-attribution">
                &#8211;{quoteAttribution}
              </p>
            </div>
            <div className="testimonial-card__stats-wrapper">
              <ul className="testimonial-card__stats-container">
                {statistics.map((stat, i) => (
                  <li className="testimonial-card__stats" key={i}>
                    <div className="testimonial-card__stats-percent">
                      {stat.percent}%
                    </div>
                    <p className="testimonial-card__stats-label">{stat.copy}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

Card.props = {
  ariaHidden: PropTypes.bool,
  color: PropTypes.oneOf(["dark-yellow", "pastel-blue", "blue", "pastel-pink"]),
  image: PropTypes.shape(Media.props).isRequired,
  quote: PropTypes.shape({
    quote: PropTypes.string.isRequired,
  }).isRequired,
  quoteAttribution: PropTypes.string,
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string,
      percent: PropTypes.number,
    })
  ),
  z: PropTypes.number,
}

Card.displayName = "TestimonialCard"

Card.propTypes = Card.props

export default Card
