import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import { uniqueId } from "lodash"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { renderClasses } from "utils/renderClasses"
import { mediumAnim } from "utils/gsapAnims"
import { useIsMounted } from "utils/useIsMounted"
import Button from "components/elements/styledButton"
import Card from "./card"
import "./paginatedScroll.scss"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const PaginatedScroll = ({ action, cards }) => {
  const numberOfCards = cards.length
  const [currentItem, setCurrentItem] = useState(0)
  const [sectionActive, setSectionActive] = useState(false)
  const [sectionAfter, setSectionAfter] = useState(false)
  const [instanceId] = useState(uniqueId("pagination-scroll-"))
  const [bgColor, setBackground] = useState(cards[0].color)
  const isMounted = useIsMounted()

  // based on data received for stickers we need to re-shape on the fly to support any static and dynamic data
  const cardsWithStickers = cards.map(card => ({
    ...card,
    image: {
      ...card.image,
      stickers: card.image.stickers?.map(sticker => ({
        ...sticker,
        icon: sticker.icon ? sticker : null,
      })),
    },
  }))

  // Scroll to card when number in carousel nav is selected
  const goToSection = i => {
    gsap.to(window, {
      duration: mediumAnim,
      ease: "cort-ease-out",
      scrollTo: {
        y: `#${instanceId}-step-${i}`,
      },
    })
  }

  // Setup ScrollTrigger code
  useEffect(() => {
    console.log("rendering")
    ScrollTrigger.create({
      end: "bottom bottom",
      onEnterBack: () => {
        if (isMounted.current) {
          setSectionAfter(false)
        }
      },
      onLeave: () => {
        if (isMounted.current) {
          setSectionAfter(true)
        }
      },
      onToggle: ({ isActive }) => {
        if (isMounted.current) {
          setSectionActive(isActive)
        }
      },
      start: "top top",
      trigger: `#${instanceId}`,
    })
  }, [instanceId, isMounted])

  // const setHighestSeenItem = React.useCallback(
  //   i => {
  //     setCurrentItem(prevHighest => {
  //       return Math.max(prevHighest, i)
  //     })
  //   },
  //   [setCurrentItem]
  // )

  // Setup scroll triggers for each step
  useEffect(() => {
    cardsWithStickers.forEach((_, i) => {
      ScrollTrigger.create({
        end: "bottom center",
        onEnter: () => {
          if (isMounted.current) {
            setCurrentItem(i)
          }
        },
        onEnterBack: () => {
          if (isMounted.current) {
            setCurrentItem(i)
          }
        },
        start: "top center",
        trigger: `#${instanceId}-step-${i}`,
      })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Update background
  useEffect(() => {
    setBackground(cardsWithStickers[currentItem].color)
  }, [currentItem, cardsWithStickers, instanceId])

  return (
    <div
      className={renderClasses(
        `paginated-scroll paginated-scroll--bg-${bgColor.toLowerCase()}`,
        [
          [sectionActive, "paginated-scroll--active"],
          [sectionAfter, "paginated-scroll--after"],
        ]
      )}
    >
      <div className="paginated-scroll__cards" id={instanceId}>
        {cardsWithStickers.map((card, i) => (
          <Card
            {...card}
            cardIndex={i}
            cardTotal={numberOfCards}
            goToSection={goToSection}
            id={`${instanceId}-step-${i}`}
            isActive={i === currentItem}
            key={i}
          />
        ))}
      </div>
      <div className="paginated-scroll__button">
        <Button {...action} color={action.color || ["yellow"]} />
      </div>
    </div>
  )
}

PaginatedScroll.props = {
  action: PropTypes.shape(Button.props),
  cards: PropTypes.arrayOf(PropTypes.shape(Card.props)).isRequired,
}

PaginatedScroll.propTypes = PaginatedScroll.props

export default PaginatedScroll
