import React from "react"
import PropTypes from "prop-types"
import { themeProps } from "../props"
import Media from "components/blocks/media"
import Heading4 from "components/type/Heading4"
import Card from "../card"
import "../card/card.scss"
import "./introCard.scss"

const IntroCard = ({
  ageJoined,
  diagnosis,
  image,
  name,
  theme = "green",
  toggleActive,
}) => (
  <button
    className={`intro-card__button intro-card--${theme}`}
    data-id={name}
    id={name}
    onClick={toggleActive}
  >
    <Card className="intro-card" theme={theme}>
      <Media
        height="auto"
        image={image}
        maxWidth="55%"
        radius="12px"
        ratio={{ height: 1, width: 1.25 }}
      />
      <Heading4 className="intro-card__text">
        <u>{name}</u> came to Cortica at {ageJoined}{" "}
        {diagnosis ? `with ${diagnosis}` : `without a clear diagnosis`}.
      </Heading4>
    </Card>
  </button>
)

IntroCard.props = {
  ...Media.props,
  ageJoined: PropTypes.string.isRequired,
  diagnosis: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

IntroCard.propTypes = {
  ...IntroCard.props,
  theme: PropTypes.oneOf(themeProps),
  toggleActive: PropTypes.func.isRequired,
}

export default IntroCard
