import React from "react"
import Base, { BaseProps } from "../base"

const Check = ({
  fill,
  stroke,
  strokeSecondary,
  strokeWidth,
  strokeWidthSecondary,
  ...props
}) => (
  <Base
    {...props}
    className="g-check"
    fill={fill}
    height="42"
    viewBox="0 0 42 42"
    width="42"
  >
    <path
      d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.95431 1 1 9.9543 1 21C1 32.0457 9.95431 41 21 41Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth="0.440489"
    />
    <path
      d="M27.6667 16.501L18.5 25.6676L14.3334 21.501"
      stroke={strokeSecondary}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </Base>
)

Check.propTypes = BaseProps

export default Check
