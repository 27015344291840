import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import Media from "components/blocks/media"
import CardLayout from "components/blocks/card"
import NumberCarouselControl from "../numberCarouselControl"
import "./card.scss"

const Card = ({
  cardIndex,
  cardTotal,
  goToSection,
  id,
  image,
  intro,
  isActive = false,
}) => (
  <div
    className={renderClasses("paginated-scroll-card max-container", [
      [isActive, "paginated-scroll-card--active"],
    ])}
    id={id}
  >
    <div className="paginated-scroll-card__body">
      <CardLayout {...intro} eyebrow={{ value: `Step ${cardIndex + 1}` }} />
      <NumberCarouselControl
        clickHandler={goToSection}
        currentItem={cardIndex}
        numberOfItems={cardTotal}
      />
    </div>
    <div className="paginated-scroll-card__media">
      <Media
        {...image}
        height="auto"
        radius="100%"
        ratio={{
          height: 1,
          width: 1,
        }}
      />
    </div>
  </div>
)

Card.propTypes = {
  cardIndex: PropTypes.number.isRequired,
  cardTotal: PropTypes.number.isRequired,
  goToSection: PropTypes.func,
  id: PropTypes.string,
  image: PropTypes.shape(Media.props),
  intro: PropTypes.shape(CardLayout.props),
  isActive: PropTypes.bool,
}

export default Card
