import React, { forwardRef, useState } from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import { renderClasses } from "utils/renderClasses"
import { Caret } from "components/icons"
import "./nav.scss"

const Nav = forwardRef(({ activeTitle, links, onClick }, ref) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleClick = () => {
    setMenuIsOpen(prevState => !prevState)
  }

  return (
    <div className="chaptered-scroll__nav max-container" ref={ref}>
      <nav className="chaptered-scroll__nav--wrapper">
        <ul
          className={renderClasses("chaptered-scroll__nav--container", [
            [menuIsOpen, "--open"],
          ])}
        >
          <li className="chaptered-scroll__nav--active-title">
            <button
              aria-selected
              onClick={handleClick}
              role="option"
              tabIndex={0}
            >
              <span>{activeTitle}</span>
              <Caret
                direction={menuIsOpen ? "down" : "up"}
                size="small"
                stroke="currentColor"
                strokeWidth="3"
              />
            </button>
          </li>
          {links?.map((link, i) => (
            <li
              className="chaptered-scroll__nav--link"
              data-id={`#${slugify(link, { lower: true, strict: true })}`}
              data-title={link}
              key={i}
            >
              <a
                href={`#${slugify(link, { lower: true, strict: true })}`}
                onClick={link => {
                  setMenuIsOpen(false)
                  if (onClick) onClick(link)
                }}
              >
                {link}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
})

Nav.props = {
  activeTitle: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.string),
  menuIsOpen: PropTypes.bool,
  onClick: PropTypes.func,
}

Nav.displayName = "Nav"

Nav.propTypes = Nav.props

export default Nav
