import React from "react"
import PropTypes from "prop-types"
import "./multiList.scss"

/**
 * Component for each multi list
 */

const MultiList = ({ list }) => (
  <ul
    className="multiList"
    style={{
      gridTemplateRows: `repeat(${Math.ceil(list.length / 2)}, auto)`,
    }}
  >
    {list.map((item, i) => (
      <li className="multiList__wrapper listItems__item" key={i}>
        <span className="multiList__title">{item.name}</span>
        <ul className="multiList__items">
          {item.list.map((c, j) => (
            <li className="multiList__item" key={j}>
              {c}
            </li>
          ))}
        </ul>
      </li>
    ))}
  </ul>
)

MultiList.props = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      list: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
    })
  ),
}

MultiList.propTypes = MultiList.props

export default MultiList
