import React from "react"
import PropTypes from "prop-types"
import { COLORS } from "utils/constants"
import { renderClasses } from "utils/renderClasses"
import Check from "components/icons/Check"
import "./table.scss"

/**
 * A table comparing two columns of content.
 */
const Table = ({
  accessibleName = "Comparison Table",
  column1Title,
  column2Title,
  removeHighlight = false,
  rows,
}) => {
  const makeCheck = string => {
    if (string.includes(":check:")) {
      return (
        <Check
          fill={COLORS.blue}
          stroke={COLORS.ivory}
          strokeSecondary={COLORS.white}
          strokeWidth="3"
        />
      )
    }
    return string
  }

  return (
    <table
      aria-label={accessibleName}
      className={renderClasses("comparison", [
        [!removeHighlight, "comparison--highlight"],
      ])}
    >
      <colgroup>
        <col className="comparison__bg comparison__title" span="1" />
        <col />
        <col className="comparison__bg" span="1" />
      </colgroup>
      <thead>
        <tr>
          <th aria-label="service offered"></th>
          <th>{column1Title}</th>
          <th>{column2Title}</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => {
          const { column1, column2, rowHeader } = row
          return (
            <tr key={i}>
              <th>{rowHeader}</th>
              <td>{column1 && makeCheck(column1)}</td>
              <td>{column2 && makeCheck(column2)}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

Table.props = {
  accessibleName: PropTypes.string,
  column1Title: PropTypes.string,
  column2Title: PropTypes.string,
  removeHighlight: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      column1: PropTypes.string,
      column2: PropTypes.string,
      rowHeader: PropTypes.string,
    })
  ),
}

Table.propTypes = Table.props

export default Table
