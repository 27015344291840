import React, { useEffect, useState, useRef } from "react"
import { useInView } from "react-hook-inview"
import PropTypes from "prop-types"

const Progress = ({ value }) => {
  const [ref, inView] = useInView({ threshold: 0.85, unobserveOnEnter: true })
  const interval = useRef(null)
  const [display, setDisplay] = useState(0)
  const elapsedTime = useRef(0)
  const totalTime = 850
  const intervalTime = 50

  useEffect(() => {
    if (!inView) return
    interval.current = setInterval(() => {
      setDisplay(val => {
        if (elapsedTime.current >= totalTime) {
          clearInterval(interval.current)
          return value
        }
        return Math.round(value * (elapsedTime.current / totalTime))
      })
      elapsedTime.current += intervalTime
    }, intervalTime)
  }, [value, inView])

  return (
    <div ref={ref}>
      <div className="barChart-statistic__percent">{display}%</div>
      <div className="barChart-statistic__wrapper">
        <div
          className="barChart-statistic__indicator"
          style={{ width: display + "%" }}
        />
      </div>
    </div>
  )
}

Progress.props = {
  value: PropTypes.number,
}

Progress.propTypes = Progress.props

export default Progress
