import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "components/sections/seo"
import Header from "components/sections/header"
import Footer from "components/sections/footer"
import Template from "./template"
import LOGO from "images/cortica-logo.svg"

const siteUrl = process.env.GATSBY_SITE_URL || "https://corticacare.com"

const TemplateContainer = ({ data: { footer, header, template } }) => {
  // Organization
  const schema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    logo: LOGO,
    name: "Cortica Care",
    sameAs: [
      "https://twitter.com/CorticaCare",
      "https://www.facebook.com/CorticaCare",
      "https://www.linkedin.com/company/cortica-/",
      "https://www.instagram.com/corticacare/",
    ],
    url: siteUrl,
  }

  return (
    <>
      <Seo {...template.meta} schemaMarkup={schema} slug={template.slug} />
      <Header {...header} />
      <Template {...template} />
      <Footer {...footer} />
    </>
  )
}

TemplateContainer.props = {
  ...Template.props,
  meta: PropTypes.shape(Seo.props),
}

TemplateContainer.propTypes = {
  data: PropTypes.shape({
    footer: PropTypes.shape(Footer.props),
    header: PropTypes.shape(Header.props),
    template: PropTypes.shape(TemplateContainer.props),
  }),
}

export default TemplateContainer

export const query = graphql`
  query GlobalPage($slug: String) {
    header: contentfulGlobalHeader(name: { eq: "Header" }) {
      announcement {
        json
      }
      menu {
        menus {
          label
          links {
            text
            path
            nestedLinks {
              text
              path
            }
          }
        }
      }
      utilityLinks {
        path
        text
      }
      cta {
        href: path
        text
      }
    }
    footer: contentfulGlobalFooter(name: { eq: "Footer" }) {
      menu {
        menus {
          label
          links {
            path
            text
          }
        }
      }
      scheduleAppointment {
        path
        text
      }
      socialLinks {
        path
        text
      }
      utilityLinks {
        path
        text
      }
    }
    template: contentfulGlobalPage(slug: { eq: $slug }) {
      slug
      title
      sections {
        __typename
        ... on ContentfulSectionCareServices {
          __typename
          services {
            cmsEntryTitle
            image {
              image {
                alt: description
                title
                file {
                  url
                }
              }
            }
            content: intro {
              eyebrow: subTitle
              action: callToAction {
                accessibilityLabel
                color
                isOutlined
                isSmall
                href: path
                text
              }
              text {
                json
              }
              title {
                text {
                  json
                }
              }
            }
          }
          title {
            text {
              json
            }
          }
        }
        ... on ContentfulSectionClinicians {
          __typename
          clinicians {
            location
            name
            roles
            image {
              alt: description
              title
              file {
                url
              }
            }
            quote {
              quote
            }
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
        ... on ContentfulSectionComparisonChart {
          __typename
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
          table {
            column1Title
            column2Title
            rows {
              column1
              column2
              rowHeader
            }
          }
        }
        ... on ContentfulSectionCoordinatedCareTeam {
          __typename
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
          teams {
            list
            name
          }
        }
        ... on ContentfulSectionDynamicScroll {
          __typename
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          cards {
            color
            image {
              image {
                alt: description
                title
                file {
                  url
                }
              }
              stickers {
                icon
                position
                theme
              }
            }
            intro {
              eyebrow: subTitle
              action: callToAction {
                accessibilityLabel
                color
                isOutlined
                isSmall
                href: path
                text
              }
              text {
                json
              }
              title {
                text {
                  json
                }
              }
            }
          }
        }
        ... on ContentfulSectionFaq {
          __typename
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
          questions {
            title: question
            text: answer {
              json
            }
          }
        }
        ... on ContentfulSectionHero {
          __typename
          dropdown: learnMoreDropdown {
            label
            options: items {
              label
              value: link
            }
          }
          text {
            json
          }
          title {
            text {
              json
            }
          }
          media {
            image {
              alt: description
              title
              file {
                url
              }
            }
          }
        }
        ... on ContentfulSectionHeroHeadline {
          __typename
          headline {
            json
          }
        }
        ... on ContentfulSectionInsuranceBanner {
          bannerText
          providers
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
        }
        #   ... on ContentfulSectionLayout {
        #     __typename
        #     textLockup: intro {
        #       eyebrow: subTitle
        #       text {
        #         json
        #       }
        #       title {
        #         text {
        #           json
        #         }
        #       }
        #     }
        #     component: content {
        #       __typename
        #       ... on ContentfulComponentMultiColumn {
        #         __typename
        #         content {
        #           text {
        #             json
        #           }
        #           title {
        #             text {
        #               json
        #             }
        #           }
        #           media {
        #             image {
        #               alt: description
        #               title
        #               file {
        #                 url
        #               }
        #             }
        #           }
        #         }
        #       }
        #     }
        #   }
        # }
        ... on ContentfulSectionPatientJourney {
          __typename
          disclaimer
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
          patients {
            ageJoined
            diagnosis
            name
            theme
            treatments: servicesUsed
            image {
              alt: description
              title
              file {
                url
              }
            }
            timeline {
              icon
              timeFrame
              text {
                text
              }
            }
          }
        }
        ... on ContentfulSectionPreFooter {
          __typename
          actions: callsToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
        ... on ContentfulSectionProductOffering {
          __typename
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
          offerings {
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
        ... on ContentfulSectionServicesHero {
          __typename
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          image {
            alt: description
            title
            file {
              url
            }
          }
          services {
            text
          }
          text: copy {
            json
          }
          title {
            text {
              json
            }
          }
        }
        ... on ContentfulSectionSplitContent {
          __typename
          backgroundColor
          reverse
          image {
            image {
              alt: description
              title
              file {
                url
              }
            }
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
        ... on ContentfulSectionStatistics {
          __typename
          footnote
          barCharts: statistics {
            copy
            percent
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
        ... on ContentfulSectionTestimonials {
          __typename
          cards {
            quoteAttribution
            image {
              image {
                alt: description
                title
                file {
                  url
                }
              }
            }
            quote {
              quote
            }
            statistics {
              percent
              copy
            }
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
        ... on ContentfulSectionWhatWeDo {
          __typename
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          content {
            media {
              image {
                alt: description
                title
                file {
                  url
                }
              }
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
        ... on ContentfulSectionWhatWeTreat {
          __typename
          treatments
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
      }
      meta: seoMetadata {
        canonical
        keywords
        title
        type
        abstract {
          abstract
        }
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
    }
  }
`
