import React from "react"
import PropTypes from "prop-types"
import { useInView } from "react-hook-inview"
import { renderClasses } from "utils/renderClasses"
import TextLockup from "components/elements/textLockup"
import Layout from "components/blocks/layout"
import DropdownNav from "components/elements/dropdownNav"
import Media from "components/blocks/media"
import "./hero.scss"

const Hero = ({ dropdown, media, shouldAnimateIn = true, text, title }) => {
  const [ref, inView] = useInView({ threshold: 0.0, unobserveOnEnter: true })

  return (
    <Layout
      actionContent={<DropdownNav {...dropdown} />}
      className="g-hero"
      layout="50-50"
      textLockup={{
        text,
        title,
      }}
    >
      <div
        className={renderClasses("g-hero-media", [
          [shouldAnimateIn, "--prep"],
          [inView && shouldAnimateIn, "--active"],
        ])}
        ref={ref}
      >
        <Media {...media} height="auto" radius="50%" ratio={{ width: 1 }} />
      </div>
    </Layout>
  )
}

Hero.props = {
  ...TextLockup.props,
  dropdown: PropTypes.shape(DropdownNav.props),
  media: PropTypes.shape(Media.props),
  shouldAnimateIn: PropTypes.bool,
}

Hero.propTypes = Hero.props

export default Hero
